import './App.css';
import Header from "./components/header"
import {Main} from './components/main'
function App() {
  return (
    <div className="App">
  <Header/>
  <Main/>
    </div>
  );
}

export default App;
